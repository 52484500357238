import ReconnectingWebSocket, { Options, ErrorEvent, Event, CloseEvent } from 'reconnecting-websocket'

/**
 * 服务器状态
 */
export enum ServerStatus {
  unknown, // 未知
  running, // 运行中
}

/**
 * 应用websocker选项
 * @see https://www.npmjs.com/package/reconnecting-websocket
 */
export interface ApplicationWebSocketOption<Data> extends Options {
  /** 协议 */
  protocols?: string | string[];
  /** 对象类型 */
  objectType?: number;
  /** 对象id */
  objectId?: string;
  /** 团队id */
  teamId?: number;
  /** 数据回调 */
  callback: (event: Data, timestamp: number) => void;
  /** 自动轮训间隔(毫秒) */
  fixed?: number;
  /** 错误回调 */
  onError?: (event: ErrorEvent) => void;
}

/**
 * 服务器状态返回
 */
export interface ServerStatusResult {
  status: ServerStatus;
  serverId: string;
  sslStartTime?: string;
  sslEndTime?: string;
}

/**
 * 容器状态返回
 */
export interface ContainerStatDetailRes{
  /// 是否运行中
  running: boolean;
  /// 错误信息
  error?: string;
  /// 状态信息
  statInfo?: ContainerStatDetail;
}
/**
 * 容器状态详情
 */
export interface ContainerStatDetail {

  systemNano: number; // 系统微秒

  memPerc: number; // 内存使用率

  cpu: number; // cpu使用率

  netInput: number; // 网络输入

  netOutput: number;// 网络输出

  blockInput: number; // 磁盘输入

  blockOutput: number; // 磁盘输出

}

/**
 * 应用状态返回
 */
export interface ApplicationStatusResult {
  [instanceName: string]: boolean;
}

/**
 * 应用websocket连接器
 */
export class ApplicationWebSocket<Data = any> {
  private connect: ReconnectingWebSocket
  private readonly wrapUrl: string
  private readonly option: ApplicationWebSocketOption<Data>
  private timer: any // timer 的类型在web和nodejs中不一致，所以这里不做类型检查

  private onerror: (event: ErrorEvent) => void = event => {
    console.error(event.error)
  }

  private onopen: (event: Event) => void = event => {
    this.option?.debug && console.log('已连接', event)
  }

  private onclose: (event: CloseEvent) => void = event => {
    this.option?.debug && console.log('连接关闭', event)
  }

  private sendQuery () {
    if (this.connectStatus === this.connect.OPEN) {
      this.connect.send('-1s')
    }
  }

  /**
   * 构建应用webSocket服务
   * @param wrapUrl
   * @param option
   */
  private constructor (wrapUrl: string, option: ApplicationWebSocketOption<Data>) {
    this.wrapUrl = wrapUrl
    this.option = option

    this.connect = new ReconnectingWebSocket(this.wrapUrl, option.protocols, { ...option, maxRetries: option.maxRetries ?? 20 })
    if (option.onError) {
      this.onError(option.onError)
    }
    this.connect.onerror = this.onerror.bind(this)
    this.connect.onopen = this.onopen.bind(this)
    this.connect.onclose = this.onclose.bind(this)
    this.connect.onmessage = (ev) => {
      option.callback(JSON.parse(ev.data), ev.timeStamp)
    }
    if (option.fixed) {
      this.timer = setInterval(this.sendQuery.bind(this), option.fixed)
    } else {
      this.connect.onopen = this.sendQuery.bind(this)
    }
  }

  /**
   * 构建状态监听websock
   * @param url
   * @param token
   * @param option
   * @returns
   */
  static createMonitorWebSocket<T> (url: string, token: string, option: ApplicationWebSocketOption<T>) {
    const wrapUrl = `${url}${url.includes('?') ? '&&' : '?'}Authorization=${token}`
    return new ApplicationWebSocket<T>(wrapUrl, option)
  }

  close () {
    this.connect?.close()
    this.timer && clearInterval(this.timer)
  }

  onError (handler: (event: ErrorEvent) => void): this {
    this.onerror = handler
    return this
  }

  onOpen (handler: (event: Event) => void): this {
    this.onopen = handler
    return this
  }

  onClose (handler: (event: CloseEvent) => void): this {
    this.onclose = handler
    return this
  }

  get connectStatus () {
    return this.connect.readyState
  }
}
