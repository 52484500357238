import { ApiResult, requestApiResult, requestRaw } from '@/libs/http-request'
import { expect } from 'chai'
import { EventSourcePolyfill } from 'event-source-polyfill'
/**
 * 对podman服务请求的辅助类
 */

/**
 * flux消息
 */
export interface TaskMessage{
    /// 消息
  message: string;

  /// 消息级别
  level: string;
}

/**
 * 上传分片文件到服务器请求
 */
export interface UploadFilePartToPodmanServerRequest{
  path: string;
  startPos: number;
  length: number;
}

/**
 * 任务请求参数
 */
export interface RequestOption{
    /// 当联结打开
    onOpen: () => void;
    /// 当收到数据
    onMessage: (message: TaskMessage) => void;
    /// 当发生错误
    onError: (err: Error) => void;

    /// 当请求结束
    onFinish?: () => void;
}

/**
 * 向podman的url发起flux请求
 * @param taskUrl
 * @param token
 * @param options
 * @returns
 */
export function requestPodmanFluxUrl (taskUrl: string, token: string, options: RequestOption) {
  expect(taskUrl, '参数taskUrl不允许为空').not.undefined.and.not.empty
  expect(options, '参数options不允许为空').not.undefined

  let isError = false

  return new Promise<EventSourcePolyfill>((resolve, reject) => {
    const es = new EventSourcePolyfill(taskUrl, {
      // headers: header,
      heartbeatTimeout: 10 * 60 * 1000,
      headers: {
        Authorization: token
      }
    })

    es.onopen = options.onOpen
    es.onmessage = (resp: any) => {
      const data = resp.data
      const taskMessage = JSON.parse(data) as TaskMessage
      options.onMessage(taskMessage)
      if (taskMessage.level === 'error') isError = true
    }

    es.onerror = (ev: any) => {
      if (options.onFinish) options.onFinish()
      es.close()
      if (isError || ev.error) {
        reject(new Error(ev.error?.message || '未知'))
      } else {
        resolve(es)
      } // 任务完成了
    }
  })
}

/**
 * 上传文件片到podman服务
 * @param taskUrl 任务地址
 * @param token
 * @param reqData
 */
export function uploadFilePartToPodmanServer (taskUrl: string, token: string,
  reqData: UploadFilePartToPodmanServerRequest, data: any): Promise<ApiResult> {
  return requestApiResult({
    url: taskUrl,
    headers: {
      fileInfo: JSON.stringify(reqData),
      Authorization: token
    },
    method: 'POST',
    data: data
  }, false)
}
