import request, { DataApiResult, requestRawResponse } from '@/libs/http-request'
import { expect } from 'chai'

export type DeployRequestType=
  'standard-webapi-buildlocal'|'standard-webapi-buildremote'|
  'standard-webfront-buildlocal'|'standard-webfront-buildrmote'|
  'image-deploy'|'nginx-image-deploy'|'notstandard-buildlocal'|
  'notstandard-remote'|'notstandard-webfront-remote'|
  'notstandard-webfront-buildlocal'

/**
 * 部署请求
 */
export interface DeployRequest {
    /// 请求的模板
    template: DeployRequestType;
    /// 项目id
    projectId?: number;

    /// 镜像id
    imageResoureId?: number;

    /// 应用id
    applicationId?: number;

    /// 容器id
    instanceId: number;

    /// 打包的容器id
    buildContainerId?: number;
  }

/**
   * 向podman服务发送的部署任务数据
   */
export interface DeployTaskItem {
    // 任务项名称
    name: string;

    // 任务项类型
    type: string;

    // 任务项执行的apiUrl
    serverApiUrl: string;

    // 服务器的目标路径,可以为空
    path: string;

    // 任务相关的数据
    data: any;
  }

/**
   * 部署结果
   */
export interface DeployResponse{
  /// 请求任务步骤的uuid
  taskStepUuid: string;
  /// 要向podman服务请求的url
  requestUrl: string;
  /// 要求请的serverId
  serverId: number;
  /// 任务数据源
  dataSource: any;
  /// 如果为oss文件写入的位置
   podManPath: string;
  /// 操作类型
  type: 'deploydata'|'remotefile'|'ossfile'|'update-instance-deploytime'|'update-appcompile-result';
}

export class DeployRequestApi {
    private baseUrl='/api/maintain/deployrequest'
    /// 请求head的key
    public static author_head_aukey='podman-authorization'
    public static author_gateway_aukey='gateway-authorization'
    public static exec_taskurl='exec-requesturl'
    public static exec_taskurl_aukey='exec-podman-authorization'

    /**
     * 请求配置任务的指令列表
     * @param reqdata
     * @returns
     */
    public requestDeployTaskList (reqdata: DeployRequest[]) {
      expect(reqdata, '参数reqdata不允许为空').not.undefined.and.not.empty

      return request<DataApiResult<DeployResponse[]>>({
        url: `${this.baseUrl}/requestDeployTaskData/${window.location.protocol === 'https:'}`,
        method: 'POST',
        data: reqdata
      })
    }

    /**
     * 下载部署任务数据
     * @param req
     * @returns
     */
    public downDeployTaskData (req: DeployResponse) {
      return requestRawResponse<any>({
        url: `${this.baseUrl}/downDeployTaskData/${window.location.protocol === 'https:'}`,
        method: 'POST',
        data: req
      }, true)
    }

    /**
     * 对oss进行请求编码
     * @param req
     * @returns
     */
    public encodeOssRequest (req: DeployResponse) {
      return new Promise<string>((resolve, reject) => {
        return requestRawResponse<any>({
          url: `${this.baseUrl}/encodeOssRequest`,
          method: 'POST',
          data: req
        }, true)
          .then((resp) => {
            resolve(resp.headers[DeployRequestApi.author_head_aukey])
          })
          .catch((ex) => reject(ex))
      })
    }
}

export default new DeployRequestApi()
