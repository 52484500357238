import request, { DataApiResult, requestRawResponse } from '@/libs/http-request'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { DeployRequestApi } from './deploy-request'
import { ApplicationStatusResult, ApplicationWebSocket, ApplicationWebSocketOption, ContainerStatDetailRes, ServerStatusResult } from '@/libs/application-websocket'
import { RequestOption, requestPodmanFluxUrl } from '@/libs/podman-request'
import axios from 'axios'

/**
 * podman请求api返回
 */
export interface PodmanApiResponse {
  code?: number;
  message?: string;
  data?: any;
}

/**
 * 应用实例管理
 */
export interface AppInstaceMangerRequestData<T>{
    /// 请求url
    requestUrl: string;

    /// 请求数据
    data?: T;

    /// 认证key
    securityKey: string;
}

/// 实例部署模板资源
export interface InstanceDeployTaskTemplateResourceItem{
  path: string;
  value?: string;
  type: string;
  params: Record<string, any>;
  dest?: string;
}

/**
 * 实例部署步骤
 */
export interface InstanceDeployTaskTemplateStep{
    /// 总任务名称
    hostTaskName: string;
    /// 任务id
    taskId: string;
    /// 步骤id
    stepId: string;
    name: string;
    destinationServer: string;
    /// 上传配置数据的服务器api地址
    upDataUrl: string;
    resources: InstanceDeployTaskTemplateResourceItem[];
}

export class AppInstanceManger {
    /// api基础地址
    private baseUrl='/api/maintain/appinstance-manger'

    /// 取得应用实例的实时日志数据
    getRealLogData (instanceId: number, option: RequestOption) {
      return new Promise<EventSourcePolyfill>((resolve, reject) => {
        requestRawResponse<DataApiResult<AppInstaceMangerRequestData<any>>>({
          url: `${this.baseUrl}/createRealLogRequestData/${instanceId}/${window.location.protocol === 'https:'}`,
          method: 'GET'
        })
          .then((response) => {
            if (response.data.data?.requestUrl) {
              resolve(requestPodmanFluxUrl(response.data.data!.requestUrl, response.headers[DeployRequestApi.author_head_aukey], option))
            } else {
              reject(response.data)
            }
          })
          .catch((err) => reject(err))
      })
    }

    /// 取得应用实例的历史日志数据
    getHistroyLogData (data: any, option: RequestOption) {
      return new Promise<EventSourcePolyfill>((resolve, reject) => {
        requestRawResponse<DataApiResult<AppInstaceMangerRequestData<any>>>({
          url: `${this.baseUrl}/createHistoryLogRequestData/${window.location.protocol === 'https:'}`,
          method: 'POST',
          data: data
        })
          .then((response) => {
            if (response.data.data?.requestUrl) {
              resolve(requestPodmanFluxUrl(response.data.data!.requestUrl, response.headers[DeployRequestApi.author_head_aukey], option))
            } else {
              reject(response.data)
            }
          })
          .catch((err) => reject(err))
      })
    }

    /// 创建应用实例的部署数据
    createAppInstanceDeloyData (instanceId: number, version: number, deployType: number) {
      return request<DataApiResult<AppInstaceMangerRequestData<InstanceDeployTaskTemplateStep>[]>>({
        // url: `${this.baseUrl}/createAppInstanceDeloyData/${instanceId}/${version}`,
        url: `${this.baseUrl}/createAppInstanceDeloyData?instanceId=${instanceId}&version=${version}&deployType=${deployType}`,
        method: 'GET'
      })
    }

    /// 删除实例
    createInstanceDeleteSeriesData (instanceId: number) {
      return request<DataApiResult<AppInstaceMangerRequestData<InstanceDeployTaskTemplateStep>[]>>({
        url: `${this.baseUrl}/createInstanceDeleteSeriesData/${instanceId}/${window.location.protocol === 'https:'}`,
        method: 'GET'
      })
    }

    /// 开启容器
    startContainer (instanceId: number, option: RequestOption) {
      return new Promise<EventSourcePolyfill>((resolve, reject) => {
        requestRawResponse<DataApiResult<AppInstaceMangerRequestData<any>>>({
          url: `${this.baseUrl}/createStartContainerData/${instanceId}/${window.location.protocol === 'https:'}`,
          method: 'GET'
        })
          .then((response) => {
            resolve(requestPodmanFluxUrl(response.data.data!.requestUrl, response.headers[DeployRequestApi.author_head_aukey], option))
          })
          .catch((err) => reject(err))
      })
    }

    /// 关闭容器
    stopContainer (instanceId: number, option: RequestOption) {
      return new Promise<EventSourcePolyfill>((resolve, reject) => {
        requestRawResponse<DataApiResult<AppInstaceMangerRequestData<any>>>({
          url: `${this.baseUrl}/createStopContainerData/${instanceId}/${window.location.protocol === 'https:'}`,
          method: 'GET'
        })
          .then((response) => {
            resolve(requestPodmanFluxUrl(response.data.data!.requestUrl, response.headers[DeployRequestApi.author_head_aukey], option))
          })
          .catch((err) => reject(err))
      })
    }

    /// 删除容器
    deleteContainer (instanceId: number, option: RequestOption) {
      return new Promise<EventSourcePolyfill>((resolve, reject) => {
        requestRawResponse<DataApiResult<AppInstaceMangerRequestData<any>>>({
          url: `${this.baseUrl}/createDeleteContainerData/${instanceId}/${window.location.protocol === 'https:'}`,
          method: 'GET'
        })
          .then((response) => {
            resolve(requestPodmanFluxUrl(response.data.data!.requestUrl, response.headers[DeployRequestApi.author_head_aukey], option))
          })
          .catch((err) => reject(err))
      })
    }

    /// 检测容器数据
    checkContainerData (instanceId: number) {
      return new Promise<any>((resolve, reject) => {
        requestRawResponse<DataApiResult<AppInstaceMangerRequestData<any>>>({
          url: `${this.baseUrl}/checkOldContainerData/${instanceId}/${window.location.protocol === 'https:'}`,
          method: 'GET'
        })
          .then((response) => {
            axios.request({
              url: response.data.data!.requestUrl,
              headers: {
                Authorization: response.headers[DeployRequestApi.author_head_aukey]
              }
            }).then((res) => {
              resolve(res)
            })
          })
          .catch((err) => reject(err))
      })
    }

    /// 删除容器数据
    deleteContainerData (instanceId: number) {
      return new Promise<any>((resolve, reject) => {
        requestRawResponse<DataApiResult<AppInstaceMangerRequestData<any>>>({
          url: `${this.baseUrl}/rmOldContainerData/${instanceId}/${window.location.protocol === 'https:'}`,
          method: 'GET'
        })
          .then((response) => {
            axios.request({
              url: response.data.data!.requestUrl,
              headers: {
                Authorization: response.headers[DeployRequestApi.author_head_aukey]
              },
              method: 'DELETE'
            }).then((res) => {
              resolve(res)
            })
          })
          .catch((err) => reject(err))
      })
    }

    /**
     * 获取容器状态socket Token
     */
    getContainerStatMonitorWebSocket (id: number, port: number, option: ApplicationWebSocketOption<ContainerStatDetailRes>) {
      return new Promise<ApplicationWebSocket<ContainerStatDetailRes>>((resolve, reject) => {
        requestRawResponse<DataApiResult<string>>({
          url: `${this.baseUrl}/getContainerStatMonitorWebSocketToken/${id}/${port}/${window.location.protocol === 'https:'}`,
          method: 'GET'
        }).then((response) => {
          if (response.data.data) {
            const ws = ApplicationWebSocket.createMonitorWebSocket<ContainerStatDetailRes>(response.data.data, response.headers[DeployRequestApi.author_head_aukey], option)
            resolve(ws)
          } else {
            reject(response.data.message)
          }
        })
      })
    }

    /**
     * 获取服务状态socket Token
     */
    getServerStatusMonitorWebSocket (id: number, port: number, option: ApplicationWebSocketOption<ServerStatusResult>) {
      return new Promise<ApplicationWebSocket<ServerStatusResult>>((resolve, reject) => {
        requestRawResponse<DataApiResult<string>>({
          url: `${this.baseUrl}/getServerStatusMonitorWebSocketToken/${id}/${port}/${window.location.protocol === 'https:'}`,
          method: 'GET'
        }).then((response) => {
          if (response.data.data) {
            const ws = ApplicationWebSocket.createMonitorWebSocket<ServerStatusResult>(response.data.data, response.headers[DeployRequestApi.author_head_aukey], option)
            resolve(ws)
          } else {
            reject(response.data.message)
          }
        })
      })
    }

    /**
     * 获取实例状态Wss联结
     */
    getInstanceStatusMonitorWebSocket (id: number, port: number, option: ApplicationWebSocketOption<ApplicationStatusResult>) {
      return new Promise<ApplicationWebSocket<ApplicationStatusResult>>((resolve, reject) => {
        requestRawResponse<DataApiResult<string>>({
          url: `${this.baseUrl}/getInstanceStatusMonitorWebSocketToken/${id}/${port}/${window.location.protocol === 'https:'}`,
          method: 'GET'
        }).then((response) => {
          if (response.data.data) {
            const ws = ApplicationWebSocket.createMonitorWebSocket<ApplicationStatusResult>(response.data.data, response.headers[DeployRequestApi.author_head_aukey], option)
            resolve(ws)
          } else {
            reject(response.data.message)
          }
        }).catch((err) => {
          reject(err)
        })
      })
    }
}

export default new AppInstanceManger()
